import React from 'react'

const GoogleAds = ({ slot, layout = 'image' }) => (
  <>
    <div>
      {layout === 'image' ? (
        <ins
          className='adsbygoogle'
          style={{ display: 'block' }}
          data-adtest={process.env.NODE_ENV !== 'production'}
          data-ad-client='ca-pub-9457875081113646'
          data-ad-slot={slot}
          data-ad-format='auto'
          data-full-width-responsive='true'
        ></ins>
      ) : (
        <ins
          className='adsbygoogle'
          style={{ display: 'block', textAlign: 'center' }}
          data-adtest={process.env.NODE_ENV !== 'production'}
          data-ad-layout='in-article'
          data-ad-format='fluid'
          data-ad-client='ca-pub-9457875081113646'
          data-ad-slot={slot}
        ></ins>
      )}
    </div>
  </>
)

export default GoogleAds
