import React from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'

import PostItem from 'components/PostItem'

import { NavItem, NavPrevTitle, PostNav as PostNavStyled, Title } from './styles'

const trackClick = item => {
  ReactGA.event({
    category: 'Post Navigation',
    action: 'click',
    label: `Post Navigation - Go to ${item}`,
  })
}

const PostNav = ({ next, previous }) => {
  return (
    <PostNavStyled>
      {previous && (
        <NavItem>
          <NavPrevTitle>Anterior</NavPrevTitle>
          <PostItem
            slug={previous.fields.slug}
            date={previous.frontmatter.date}
            title={previous.frontmatter.title}
            timeToRead={previous.timeToRead}
            isMini={true}
            gaLabel='Post Navigation'
            onClick={() =>
              trackClick(`previous page ${previous.frontmatter.title}`)
            }
          />
        </NavItem>
      )}
      {next && (
        <NavItem>
          <Title>Próximo</Title>
          <PostItem
            slug={next.fields.slug}
            date={next.frontmatter.date}
            title={next.frontmatter.title}
            timeToRead={next.timeToRead}
            isMini={true}
            gaLabel='Post Navigation'
            onClick={() => trackClick(`next page ${next.frontmatter.title}`)}
          />
        </NavItem>
      )}
    </PostNavStyled>
  )
}

PostNav.propTypes = {
  previous: PropTypes.object,
  next: PropTypes.object,
}

export default PostNav
