import React from 'react'
import { graphql } from 'gatsby'

import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

import Container from 'components/Container'
import SEO from 'components/Seo'

import PostHeader from 'components/PostItem/PostHeader'
import PostFooter from 'components/PostItem/PostFooter'
import PostNav from 'components/PostItem/PostNav'
import GoogleAds from 'components/PostItem/GoogleAds'
import Content from 'components/Content'
import DisqusWrapper from 'components/DisqusWrapper'

const BlogPost = props => {
  const post = props.data.markdownRemark
  const next = props.pageContext.next
  const previous = props.pageContext.previous

  let disqusConfig = {
    url: `https://allisonverdam.com.br/blog/${post.slug}`,
    identifier: post.id,
    title: post.title,
  }

  return (
    <Container>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={
          post.frontmatter.image
            ? `https://allisonverdam.com.br${post.frontmatter.image.publicURL}`
            : 'https://allisonverdam.com.br/assets/og-image.jpg'
        }
      />
      <section>
        <div itemScope itemType='http://schema.org/Article'>
          <PostHeader
            image={post.frontmatter.image}
            tags={post.frontmatter.tags}
            date={post.frontmatter.date}
            title={post.frontmatter.title}
            description={post.frontmatter.description}
            timeToRead={post.timeToRead}
          />
          <GoogleAds slot='9527748570' layout='inArticle'></GoogleAds>
          <Content>
            <div
              itemProp='articleBody'
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </Content>
          <PostFooter />
          <GoogleAds slot='4000089720' layout='image'></GoogleAds>
          <PostNav previous={previous} next={next} />
          <DisqusWrapper>
            <Disqus config={disqusConfig} />
          </DisqusWrapper>
        </div>
      </section>
    </Container>
  )
}

export default BlogPost

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        description
        title
        tags
        image {
          id
          publicURL
          childImageSharp {
            fluid(maxWidth: 1280, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
    }
  }
`
