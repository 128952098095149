import styled from 'styled-components'
import Img from 'gatsby-image'

import { Space, Height, ZIndex, Title, Subtitle1 } from 'utils/theme'

export const Header = styled.header`
  &:not(:last-child) {
    margin-bottom: ${Space.lg};
  }
`

export const ButtonBack = styled.div`
  &:not(:first-child) {
    margin-top: ${Space.default};
  }

  &:not(:last-child) {
    margin-bottom: ${Space.default};
  }
`

export const PostImage = styled(Img).attrs({
  alt: 'Imagem de introdução',
  'aria-hidden': 'true',
})`
  display: block;
  height: 25vh;
  left: calc(-50vw + 50%);
  margin-top: -${Height.mainTop};
  object-fit: cover;
  position: relative;
  width: 100vw;

  &:not(:last-child) {
    margin-bottom: ${Space.default};
  }

  &:after {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${ZIndex.default};
  }
`

export const TitlePost = styled.h1.attrs({
  itemProp: 'name',
})`
  ${Title}

  &:not(:first-child) {
    margin-top: ${Space.sm};
  }

  &:not(:last-child) {
    margin-bottom: ${Space.xs};
  }
`

export const Subtitle = styled.h2.attrs({
  itemProp: 'description',
})`
  ${Subtitle1}

  &:not(:last-child) {
    margin-bottom: ${Space.default};
  }
`
export const Author = styled.h3.attrs({
  itemProp: 'author',
})`
  position: absolute;
  top: -100vh;
`
