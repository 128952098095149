import styled from 'styled-components'

import { Space } from 'utils/theme'

export const DateTime = styled.time`
  color: var(--secondaryColor);
  display: block;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;

  &:not(:last-child) {
    margin-bottom: ${Space.xxs};
  }
`
