import styled from 'styled-components'
import media from 'styled-media-query'

import { Border, Space, Subtitle2 } from 'utils/theme'

export const PostNav = styled.nav`
  ${media.greaterThan('small')`
    display: flex;
    justify-content: space-between;
  `}

  &:not(:first-child) {
    border-top: ${Border.default};
    margin-top: ${Space.lg};
    padding-top: ${Space.default};
  }
`

export const Title = styled.h2`
  ${Subtitle2}
  height: ${Space.default};
  margin-bottom: ${Space.sm};
`

export const NavPrevTitle = styled.p`
  ${Subtitle2}
  height: ${Space.default};
  margin-bottom: ${Space.sm};
`

export const NavItem = styled.div`
  display: block;

  ${media.lessThan('small')`
    &:not(:last-child) {
      margin-bottom: ${Space.default};
    }
  `}

  ${media.greaterThan('small')`
    max-width: calc(50% - ${Space.xs});
    width: 50%;

    > a {
      height: calc(100% - ${Space.default} - ${Space.sm});
    }
  `}
`
