import React from 'react'
import PropTypes from 'prop-types'

import { DateTime as DateTimeStyled } from './styles'

const DateTime = ({ children }) => {
  return <DateTimeStyled>{children}</DateTimeStyled>
}

DateTime.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DateTime
