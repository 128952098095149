import styled from 'styled-components'

import { Space, Border, Text1, Subtitle1 } from 'utils/theme'

export const PostFooter = styled.div`
  ${Text1}
  margin-bottom: ${Space.lg};
`

export const SocialMediaContainer = styled.p`
  margin-left: 10px;
`

export const PostFooterContent = styled.div`
  margin-bottom: ${Space.md};
  margin-top: ${Space.md};
  padding-top: ${Space.md};
  position: relative;

  &:after {
    border-top: ${Border.default};
    content: '';
    left: 20%;
    position: absolute;
    right: 20%;
    top: 0;
  }
`

export const Title = styled.h2`
  ${Subtitle1}

  &:not(:last-child) {
    margin-bottom: ${Space.sm};
  }
`
