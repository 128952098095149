import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Tags from 'components/Tags'
import { GreaterThanTablet } from 'components/Responsive'

import MediaQuery from 'react-responsive'
import {
  Header,
  PostImage,
  ButtonBack,
  Author,
  Subtitle,
  TitlePost,
} from './styles'
import AniLinkButton from 'components/AniLinkButton'
import DateTime from 'components/PostItem/DateTime'

const PostHeader = ({ image, tags, date, timeToRead, title, description }) => {
  return (
    <Header>
      {image && (
        <GreaterThanTablet>
          <div>
            <PostImage fluid={image.childImageSharp.fluid} />
          </div>
        </GreaterThanTablet>
      )}
      <ButtonBack>
        <AniLinkButton rel='prev' to='/blog/'>
          ← Buscar outro conteúdo
        </AniLinkButton>
      </ButtonBack>
      <DateTime>
        <span itemProp='datePublished'>{date}</span>
        {timeToRead && <span> · Leitura de {timeToRead} min</span>}
      </DateTime>
      <TitlePost>{title}</TitlePost>
      <Subtitle>{description}</Subtitle>
      <Author>Allison Verdam</Author>
      <Tags tags={tags} isLink={true} />
    </Header>
  )
}

PostHeader.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  image: PropTypes.object,
  timeToRead: PropTypes.number,
}

export default PostHeader
