import React from 'react'
import { TwitterSquare, Linkedin, GithubSquare } from '@styled-icons/fa-brands'

import {
  PostFooter as PostFooterStyled,
  PostFooterContent,
  Title,
  SocialMediaContainer,
} from './styles'

const PostFooter = () => {
  return (
    <PostFooterStyled>
      <PostFooterContent>
        <Title>Me siga nas redes sociais!</Title>

        <SocialMediaContainer>
          <a
            href='https://twitter.com/allisonverdam'
            target='_blank'
            rel='noopener noreferrer'
            title='Ver Twitter'
          >
            <TwitterSquare width='20' style={{ marginRight: 5 }} />
            @allisonverdam
          </a>
          <br />
          <a
            href='https://github.com/allisonverdam'
            target='_blank'
            rel='noopener noreferrer'
            title='Ver Github'
          >
            <GithubSquare width='20' style={{ marginRight: 5 }} />
            @allisonverdam
          </a>
          <br />
          <a
            href='https://www.linkedin.com/in/allisonverdam/'
            target='_blank'
            rel='noopener noreferrer'
            title='Ver Linkedin'
          >
            <Linkedin width='20' style={{ marginRight: 5 }} />
            @allisonverdam
          </a>
        </SocialMediaContainer>
      </PostFooterContent>
    </PostFooterStyled>
  )
}

export default PostFooter
