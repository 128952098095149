import styled from 'styled-components'

import { Space } from 'utils/theme'

export const DisqusWrapper = styled.footer`
  margin-top: ${Space.lg};

  #disqus_thread {
    .publisher-anchor-color {
      background-color: red;
    }
  }
`
